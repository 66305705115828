import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import SkillBar from 'react-skillbars'; // Assuming you are using react-skillbars
import Feature from '../../components/feature/Feature';
import './Intro.css';

const WhatGPT3 = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Define the skills data
  const skills = [
    {
      type: 'Java',
      level: 100,
      color: {
        bar: '#3498db',
        title: { text: '#fff', background: '#2980b9' }
      }
    },
    {
      type: 'React',
      level: 85,
      color: {
        bar: '#4288d0',
        title: { text: '#fff', background: '#124e8c' }
      }
    },
    {
      type: 'Javascript',
      level: 75,
      color: {
        bar: '#2c3e50',
        title: { text: '#fff', background: '#2c3e50' }
      }
    },
    {
      type: 'Spring',
      level: 50,
      color: {
        bar: '#2ecc71',
        title: { text: '#fff', background: '#27ae60' }
      }
    }
  ];

  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
      <div className="gpt3__whatgpt3-feature">
        <Feature title="About Me" text="I'm a student passionate about software development. I enjoy problem-solving and the process of creating functional, user-friendly applications." />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Explore My Work and Skills</h1>
        <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
          Explore My Work and Skills
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: 'popover-paper' }}
        >
          <div className="popover-content" style={{ textAlign: 'center' }}>
            <h3>My Skills</h3>
            <div className="skills-container">
              <SkillBar skills={skills} height={20} />
              <p>Hold Up I'm learning these Skills</p>
            </div>
          </div>
        </Popover>
        <p>Discover my projects and areas of expertise</p>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature title="Projects" text="Check out my projects where I've applied my knowledge and skills to create functional applications." />
        <Feature title="Skills" text="Discover the programming languages, tools, and techniques I've learned and applied in my projects." />
        <Feature title="Education" text="Learn about my educational background and the coursework that has contributed to my knowledge base." />
      </div>
    </div>
  );
};

export default WhatGPT3;