import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Full-Stack Development',
    text: 'I am learning to build scalable web applications using modern technologies like React, Node.js, and Express.',
  },
  {
    title: 'Mobile App Development',
    text: 'I am gaining experience in developing cross-platform mobile applications using React Native and Kotlin.',
  },
  {
    title: 'Machine Learning',
    text: 'I am acquiring skills in creating machine learning models for predictive analytics and data-driven decision making.',
  },
  {
    title: 'Data Visualization',
    text: 'I am becoming proficient in visualizing complex datasets using tools like D3.js and Tableau to derive actionable insights.',
  },
];

const Features = () => (
  <div className="portfolio__features section__padding" id="features">
    <div className="portfolio__features-heading">
      <h1 className="gradient__text">Explore My Learning Journey</h1>
      <p>Discover the skills and technologies I am currently learning.</p>
    </div>
    <div className="portfolio__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;