import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="portfolio__cta">
    <div className="portfolio__cta-content">
      <p>Interested in My Journey?</p>
      <h3>Connect with me to learn more about my projects and skills.</h3>
    </div>
    <div className="spotify-embed">
      <iframe
        title="Favorite Songs Playlist"
        style={{ borderRadius: '12px' }}
        src="https://open.spotify.com/embed/playlist/707aSJTnSIl4a8zV3j9gbv?utm_source=generator&theme=0"
        width="100%"
        height="152"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  </div>
);

export default CTA;