import React, { useEffect } from 'react';
import './Experience.css';

const Experience = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//cdn.credly.com/assets/utilities/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Fade-in effect on scroll
    const badge = document.querySelector('.credly-badge');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-visible');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    });

    if (badge) {
      observer.observe(badge);
    }

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);

  return (
    <div className="gpt3__brand section__padding">
      <div className="gradient__text"> 
        <h3 className="section-heading">Skills</h3>
      </div>
      <p align="left" className="gpt3__skills">
        <img src="https://img.shields.io/badge/JavaScript-F7DF1C?logo=javascript&logoColor=white" height="40" alt="JavaScript" />
        <img src="https://img.shields.io/badge/React-20232A?logo=react&logoColor=61DAFB" height="40" alt="React" />
        <img src="https://img.shields.io/badge/TypeScript-3178C6?logo=typescript&logoColor=white" height="40" alt="TypeScript" />
        <img src="https://img.shields.io/badge/Node.js-8CC84B?logo=node.js&logoColor=white" height="40" alt="Node.js" />
        <img src="https://img.shields.io/badge/MongoDB-4EA94B?logo=mongodb&logoColor=white" height="40" alt="MongoDB" />
        <img src="https://img.shields.io/badge/MySQL-4479A1?logo=mysql&logoColor=white" height="40" alt="MySQL" />
        <img src="https://img.shields.io/badge/Python-306998?logo=python&logoColor=white" height="40" alt="Python" />
        <img src="https://img.shields.io/badge/C%2B%2B-F34B7F?logo=c%2B%2B&logoColor=white" height="40" alt="C++" />
        <img src="https://img.shields.io/badge/Spring-6DB33F?logo=spring&logoColor=white" height="40" alt="Spring" />
        <img src="https://img.shields.io/badge/React_Native-20232A?logo=react&logoColor=61DAFB" height="40" alt="React Native" />
        <img src="https://img.shields.io/badge/Firebase-FFCA28?logo=firebase&logoColor=white" height="40" alt="Firebase" />
        <img src="https://img.shields.io/badge/Azure_DevOps-0078D7?logo=azure-devops&logoColor=white" height="40" alt="Azure DevOps" />
      </p>
      <div className="gradient__text"> 
        <h3 className="section-heading">GitHub Stats</h3>
      </div>
      <div className="gpt3__github-stats">
        <img src="https://github-readme-stats.vercel.app/api?username=talha1230&theme=react&hide_title=false&hide_rank=false&show_icons=false&include_all_commits=false&count_private=true&line_height=23" alt="GitHub stats Card" />
        <img src="https://streak-stats.demolab.com/?user=talha1230&theme=react&hide_border=false&date_format=M+j%5B%2C+Y%5D&mode=daily&hide_total_contributions=false&hide_current_streak=false&hide_longest_streak=false&card_height=200" alt="GitHub streak Card" />
      </div>
      <div className="gpt3__github-stats">
        <img src="https://github-readme-stats.vercel.app/api/top-langs?username=talha1230&theme=react&hide_title=false&layout=compact&langs_count=6&hide_progress=false&card_width=400" alt="GitHub top-langs Card" />
        <div className="credly-badge puff-in-center">
          <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="a0ebb606-b291-4074-97bb-085ab919753b" data-share-badge-host="https://www.credly.com"></div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
