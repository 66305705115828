import React, { useState, useRef, useEffect } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import emailjs from 'emailjs-com';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [formData, setFormData] = useState({
    from_name: '',
    reply_to: '',
    message: ''
  });

  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_btcu4pd', 'template_bn3q8j6', e.target, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message, please try again.');
      });

    setFormData({
      from_name: '',
      reply_to: '',
      message: ''
    });
    setToggleForm(false);
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setToggleForm(false);
    }
  };

  useEffect(() => {
    if (toggleForm) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleForm]);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_container">
          <p><a href="#home">Home</a></p>
          <p><a href="#wgpt3">About Me</a></p>
          <p><a href="#possibility">Skills</a></p>
          <p><a href="#features">Projects</a></p>
          <p><a href="#blog">Education</a></p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <button type="button" onClick={() => setToggleForm(!toggleForm)}>Get in Touch</button>
      </div>
      {toggleForm && (
        <div className="form-container" ref={formRef}>
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="from_name" value={formData.from_name} onChange={handleChange} required placeholder="Enter Your Full Name" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="reply_to" value={formData.reply_to} onChange={handleChange} required placeholder="Enter Your Email" />
            </div>
            <div className="form-group">
              <label htmlFor="textarea">Message?</label>
              <textarea name="message" id="textarea" rows="10" cols="50" value={formData.message} onChange={handleChange} required placeholder="Please Type Your Message here and click on Submit Button Below, I'll Follow up via Email"></textarea>
            </div>
            <button className="form-submit-btn" type="submit">
              Send Message
            </button>
          </form>
        </div>
      )}
      <div className="gpt3__navbar-menu">
        <RiMenu3Line size={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p><a href="#home">Home</a></p>
              <p><a href="#wgpt3">About Me</a></p>
              <p><a href="#possibility">Skills</a></p>
              <p><a href="#features">Projects</a></p>
              <p><a href="#blog">Education</a></p>
            </div>
            <div className="gpt3__navbar-menu_container-links-sign">
              <button type="button" onClick={() => setToggleForm(!toggleForm)}>Get in Touch</button>
            </div>
            <RiCloseLine size={27} onClick={() => setToggleMenu(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;